.navbar {
    background-color: #2f4355;
}

.navLink {
    display: block;
    padding: 15px;
    background-color: #2f4355;
    color: #fff;
}

.navLink:hover {
    background-color: rgba(17, 41, 70, 0.5);
    color: #fff;
}